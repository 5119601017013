import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Descriptions } from 'antd';

const MetaWrap = styled.div``;

const ImageInfo = ({ meta, name }) => {
    const camera = meta.make || meta.model ? `${meta.make || ''} ${meta.model || ''}` : null;
    return (
        <MetaWrap>
            <Descriptions column={2} size={'middle'} bordered>
                <Descriptions.Item label="File name">{name}</Descriptions.Item>
                {camera ? <Descriptions.Item label="Camera">{camera}</Descriptions.Item> : null }
                {meta.lat ? <Descriptions.Item label="Latitude">{meta.lat}</Descriptions.Item> : null }
                {meta.lng ? <Descriptions.Item label="Longtitude">{meta.lng}</Descriptions.Item> : null }
                {meta.altitude ? <Descriptions.Item label="Altitude">{`${meta.altitude}m`}</Descriptions.Item> : null }
                {meta.lng && meta.lat
                    ? <Descriptions.Item label="Location"><a href={`https://www.google.com/maps/@${meta.lat},${meta.lng},51m/data=!3m1!1e3`} target={'_blank'} rel="noopener noreferrer">View on map</a></Descriptions.Item>
                    : null
                }
            </Descriptions>
        </MetaWrap>
    )
}

ImageInfo.propTypes = {
    meta: PropTypes.object,
    name: PropTypes.string,
};

export default ImageInfo;
