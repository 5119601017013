import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import Main from './components/main';

function App() {

  return (
    <Main />
  );
}

export default App;
