import React, { useState } from 'react';
import styled from 'styled-components';
import EXIF from "exif-js";
import Helmet from 'react-helmet';
import ImageInfo from './ImageInfo';

// we need to turn degree, min, sec format into decimal
const DMS2DD = (degrees, minutes, seconds, direction) => {
    var dd = degrees + (minutes/60) + (seconds/3600);
    if (direction === "S" || direction === "W") {
      dd = dd * -1;
    }
    return dd;
}

const MainWrap = styled.div`
width: 1200px;
max-width: 100%;
margin 32px auto;
.imagePreview {
    max-width: 100%;
    border: 1px solid #f0f0f0;
    padding: 8px;
    margin-bottom: 8px;
}
.nameOnly {
    border: 1px solid #f0f0f0;
    padding: 16px;
    margin-bottom: 48px;
}
.imageInput {
    margin-bottom: 16px;
}
@media print {
    width: 100%;
    .imageInput {
        display: none;
    }
 }
`;

const Main = () => {
    const [meta, setMeta] = useState([]);
    const [fileDataURL, setFileDataURL] = useState([]);
    const [filenames, setFileNames] = useState([]);

    const handleChange = async ({ target }) => {
        // Reset data
        setMeta([]);
        setFileDataURL([]);
        setFileNames([]);

        if(target && target.files) {
            // Create an array with names only in case we get an error in the exif reading and we can't get the metadata
            let names = Array.from(target.files).map((file, index) => file.name);
            setFileNames(names);
            
            let files = Array.from(target.files).map((file, index) => {

                // Define a new file reader
                let reader = new FileReader();
                // Create a new promise
                return new Promise(resolve => {
                    // Resolve the promise after reading file
                    reader.onload = () => { 
                        return resolve(reader.result)};
                    // Read the file as a dataUrl
                    reader.readAsDataURL(file);
                });

            });

            // At this point you'll have an array of results
            let res = await Promise.all(files);

            setFileDataURL(res);
            compileExif(Array.from(target.files));
        }
    };

    const compileExif = async (files) => {
        let exif = files.map((file, index) => {
            return new Promise(resolve => {
                let lng, lat, altitude, make, model, date;
                const name = file.name;
                // Extract exif data
                EXIF.getData(file, function() {
                    // let exifData = EXIF.pretty(this);
                    let data = this;
                    if (data) {
                        // console.log(exifData);
                        // console.log(EXIF.getTag(this, "GPSAltitude"));
                        // console.log(EXIF.getTag(this, "GPSLongitude"));
                        // console.log(EXIF.getTag(this, "GPSLatitude"));
                        // console.log(EXIF.getTag(this, "Make"));
                        // console.log(EXIF.getTag(this, "Model"));
                        // console.log(EXIF.getTag(this, "DateTimeOriginal"));

                        //if (data.exifdata.GPSLatitude && data.exifdata.GPSLatitudeRef) lat = getLat(data.exifdata.GPSLatitude, data.exifdata.GPSLatitudeRef)
                        // latitude in decimal
                        const latDeg = data.exifdata.GPSLatitude ? parseFloat(data.exifdata.GPSLatitude[0]) : null;
                        const latMin = data.exifdata.GPSLatitude ? parseFloat(data.exifdata.GPSLatitude[1]) : null;
                        const latSec = data.exifdata.GPSLatitude ? parseFloat(data.exifdata.GPSLatitude[2]) : null;
                        const latDir = data.exifdata.GPSLatitudeRef || null;
                        lat = DMS2DD(latDeg, latMin, latSec, latDir);
                        

                        // longitude in decimal
                        const lngDeg = data.exifdata.GPSLongitude ? parseFloat(data.exifdata.GPSLongitude[0]) : null;
                        const lngMin = data.exifdata.GPSLongitude ? parseFloat(data.exifdata.GPSLongitude[1]) : null;
                        const lngSec = data.exifdata.GPSLongitude ? parseFloat(data.exifdata.GPSLongitude[2]) : null;
                        const lngDir = data.exifdata.GPSLongitudeRef || null;
                        lng = DMS2DD(lngDeg, lngMin, lngSec, lngDir);

                        altitude = data.exifdata.GPSAltitude ? parseFloat(data.exifdata.GPSAltitude) : null;
                        make = data.exifdata.Make || null;
                        model = data.exifdata.Model || null;
                        date = data.exifdata.DateTimeOriginal || null;

                        return resolve({lat, lng, altitude, index, make, model, date, name});
                    } else {
                        return resolve({ name });
                    }
                });
            });
        });

        let res = await Promise.all(exif);
        setMeta(res);
    };

  return (
    <MainWrap>
        <Helmet>
          <title>
            Solar report
          </title>
        </Helmet>

        <input
            type="file"
            id="file"
            multiple
            accept=".jpg, .png, .heif, .heic"
            onChange={handleChange}
            className={'imageInput'}
        />

      {fileDataURL && fileDataURL.length
        ? fileDataURL.map((f, index) => (
          <div key={`image-${index}`} style={{ marginBottom: '48px'}}>
            
            <img src={f || null} alt={'preview'} className={'imagePreview'}/>

            {meta && meta[index]
                ? <ImageInfo meta={meta[index]} name={filenames[index]} />
                : <div className={'nameOnly'}>{filenames[index]}</div>
            }

          </div>
        ))
        : null
      }
    </MainWrap>
  );
}

export default Main;